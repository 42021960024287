
  import { defineComponent, ref } from "vue";
  import { useRoute} from "vue-router";
  import axios from "@/api/axios";
  import Toast from "@/components/UI/Toast";
  import UiForm from "@/components/common/QuestionForm.vue";
  import dateFormat from "@/utils/dateFormat";
  import UiButton from "@/components/UI/Button";
  import Loading from "@/components/UI/Loading/src/Loading.vue";
  import qs from "qs";
  export default defineComponent({
    name: "SaleOrderDetail",
    components: {
      UiForm,
      UiButton,
      Loading,
    },
    setup() {
      const salesOrder = ref(null);
      const orderDetails = ref([]);
      const route = useRoute();

      const id = route.query.id;

//
      const content = ref("");
      const files = ref([]);

      const imageReg = /image\/(.+)/;
      const videoReg = /video\/(.+)/;
      function upload(files: any[]) {
        return new Promise((resolve, reject) => {
          const fileEmpty = files.length === 0;
          let url = "";
          let filePath = "",
                  videos = "",
                  isImg = false,
                  isVideo = false;

          if (fileEmpty) {
            resolve({ filePath, videos });
          }
          const file = files[0].file;
          if (imageReg.test(file.type)) {
            url = "/M/FroalaApi/UploadImage";
            isImg = true;
          }
          if (videoReg.test(file.type)) {
            url = "/M/FroalaApi/UploadVideo";
            isVideo = true;
          }

          const formData = new FormData();
          files.forEach((item) => {
            formData.append("file", item.file);
          });
          try {
            axios
              .post(url, formData, {
                timeout: 0,
              })
              .then((res) => {
                      if (res.data.success) {
                        if (isImg) {
                          filePath = res.data.returnUrl;
                        }
                        if (isVideo) {
                          videos = res.data.returnUrl;
                        }
                        resolve({ filePath, videos });
                      }
                    });
          } catch (e) {
            Toast({
              type: "error",
              title: Object.prototype.toString.call(e),
            });
          }
        });
      }

      const loading = ref(false);
      const loadOption = {
        text: "正在上传...",
        color: "#b9135a",
        textColor: "#b9135a",
      };
      function submitSale() {
        if (content.value == "") {
          Toast({
            title: "请输入相关内容！",
            type: "warning",
          });
          return false;
        }

        loading.value = true;
        upload(files.value).then((res: any) => {
          const data = qs.stringify({
            salesOrderId:id,
            contents: content.value,
            ...res,
          });
          axios
            .post("/M/salesOrder/doSaveSalesOrderDetail?rnd=", data)
            .then((res) => {
              if (res.data.success) {
                loading.value = false;
                Toast({
                  type: "success",
                  title: res.data.msg,
                });
                window.location.reload()
              } else {
                loading.value = false;
                Toast({
                  type: "error",
                  title: res.data.msg,
                });
              }
            })
            .catch((err) => {
              loading.value = false;
              console.log("err");
            });
        });
      }

//





      axios
        .get("/M/salesOrder/saleOrderDetail", {
          params: {
            id: id,
            validate: true,
          },
        })
        .then((res) => {
          if (res.data.success) {
            salesOrder.value = res.data.obj.salesOrder;
            res.data.obj.orderDetails.forEach((item: any) => {
              item.filePath = item.filePath ? item.filePath.split(",") : [];
              item.expand = true;
            });
            orderDetails.value = res.data.obj.orderDetails;

          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((e) => {
          Toast({
            type: "error",
            title: e.message || e,
          });
        });
      return {
        content,
        files,
        submitSale,
        loading,
        loadOption,
        orderDetails,
        salesOrder,
        dateFormat
      };
    },

  });
