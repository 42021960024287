<template>
  <main v-if="salesOrder">
    <div class="salesOrder-top">
      <div style="display: inline-block;">订单号：{{ salesOrder.ono }}</div>
      <div class="salesOrder-status">当前状态：
        <span v-if="salesOrder.status == 0" style="color: #b9135a;">待解决</span>
        <span v-if="salesOrder.status == 1" style="color: #b9135a;">处理中</span>
        <span v-if="salesOrder.status == 2" style="color: #b9135a;">已解决</span>
      </div>
    </div>
    <div class="salesOrder-menu">
      <div>售后记录</div>
    </div>
    <ul class="salesOrder-ul">
      <li  v-for="(item, index) in orderDetails" :key="index">
        <a class="user-img" href="">
          <img :src="item.userImg" lt="" />
        </a>
        <div class="single_detail_list_right">
          <div class="single_detail_list_people">
            <span v-if="item.isAoke && item.isAoke == 1">
             售后客服（{{ item.realName }}）
            </span>
            <span v-else>{{ item.realName }}</span>
            <div>{{ dateFormat(item.createTime) }}</div>
          </div>
          <div class="single_detail_list_explain">{{ item.contents }}</div>
        </div>
        <div class="video-wapper" v-show="item.expand">
          <img
              v-for="(itm, i) in item.filePath"
              :key="i"
              :src="itm"
              alt=""
          />
          <video
                  v-if="item.videos && item.videos != ''"
                  controls
                  :src="item.videos"
          ></video>
        </div>
      </li>
    </ul>
    <div v-if="salesOrder.status !=2">
      <ui-form
              v-model:content="content"
              v-model:file="files"
      ></ui-form>
      <ui-button class="submit" type="submit" @handleclick="submitSale()">保存</ui-button>
      <loading :options="loadOption" v-if="loading"></loading>
    </div>
  </main>
</template>

<script lang="ts">
  import { defineComponent, ref } from "vue";
  import { useRoute} from "vue-router";
  import axios from "@/api/axios";
  import Toast from "@/components/UI/Toast";
  import UiForm from "@/components/common/QuestionForm.vue";
  import dateFormat from "@/utils/dateFormat";
  import UiButton from "@/components/UI/Button";
  import Loading from "@/components/UI/Loading/src/Loading.vue";
  import qs from "qs";
  export default defineComponent({
    name: "SaleOrderDetail",
    components: {
      UiForm,
      UiButton,
      Loading,
    },
    setup() {
      const salesOrder = ref(null);
      const orderDetails = ref([]);
      const route = useRoute();

      const id = route.query.id;

//
      const content = ref("");
      const files = ref([]);

      const imageReg = /image\/(.+)/;
      const videoReg = /video\/(.+)/;
      function upload(files: any[]) {
        return new Promise((resolve, reject) => {
          const fileEmpty = files.length === 0;
          let url = "";
          let filePath = "",
                  videos = "",
                  isImg = false,
                  isVideo = false;

          if (fileEmpty) {
            resolve({ filePath, videos });
          }
          const file = files[0].file;
          if (imageReg.test(file.type)) {
            url = "/M/FroalaApi/UploadImage";
            isImg = true;
          }
          if (videoReg.test(file.type)) {
            url = "/M/FroalaApi/UploadVideo";
            isVideo = true;
          }

          const formData = new FormData();
          files.forEach((item) => {
            formData.append("file", item.file);
          });
          try {
            axios
              .post(url, formData, {
                timeout: 0,
              })
              .then((res) => {
                      if (res.data.success) {
                        if (isImg) {
                          filePath = res.data.returnUrl;
                        }
                        if (isVideo) {
                          videos = res.data.returnUrl;
                        }
                        resolve({ filePath, videos });
                      }
                    });
          } catch (e) {
            Toast({
              type: "error",
              title: Object.prototype.toString.call(e),
            });
          }
        });
      }

      const loading = ref(false);
      const loadOption = {
        text: "正在上传...",
        color: "#b9135a",
        textColor: "#b9135a",
      };
      function submitSale() {
        if (content.value == "") {
          Toast({
            title: "请输入相关内容！",
            type: "warning",
          });
          return false;
        }

        loading.value = true;
        upload(files.value).then((res: any) => {
          const data = qs.stringify({
            salesOrderId:id,
            contents: content.value,
            ...res,
          });
          axios
            .post("/M/salesOrder/doSaveSalesOrderDetail?rnd=", data)
            .then((res) => {
              if (res.data.success) {
                loading.value = false;
                Toast({
                  type: "success",
                  title: res.data.msg,
                });
                window.location.reload()
              } else {
                loading.value = false;
                Toast({
                  type: "error",
                  title: res.data.msg,
                });
              }
            })
            .catch((err) => {
              loading.value = false;
              console.log("err");
            });
        });
      }

//





      axios
        .get("/M/salesOrder/saleOrderDetail", {
          params: {
            id: id,
            validate: true,
          },
        })
        .then((res) => {
          if (res.data.success) {
            salesOrder.value = res.data.obj.salesOrder;
            res.data.obj.orderDetails.forEach((item: any) => {
              item.filePath = item.filePath ? item.filePath.split(",") : [];
              item.expand = true;
            });
            orderDetails.value = res.data.obj.orderDetails;

          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((e) => {
          Toast({
            type: "error",
            title: e.message || e,
          });
        });
      return {
        content,
        files,
        submitSale,
        loading,
        loadOption,
        orderDetails,
        salesOrder,
        dateFormat
      };
    },

  });
</script>

<style lang="scss" scoped>
  main {
    padding-bottom: 109.987px;
    width: 355.013px;
    margin: 0 auto;
  }
  .menu-nav {
    overflow: hidden;
    height: 45px;
    line-height: 45px;
    border-bottom: 6.487px solid #f4f4f4;
  }

  .menu-nav li {
    float: left;
    width: 50%;
    text-align: center;
  }
  .menu-nav li a {
    display: block;
    text-align: center;
    line-height: 45px;
    width: 100%;
    font-size: 16.012px;
    color: #666;
  }
  .menu-nav .active a {
    color: #b9135a;
  }

  .empty {
    padding-top: 160.013px;
    text-align: center;
    padding-bottom: 49.987px;

    color: #9f9f9f;
    font-size: 13.012px;
  }
  .empty img {
    width: 132.487px;
    height: 103.988px;
    display: block;
    margin: 0 auto;
    margin-bottom: 15px;
  }
  .empty a {
    font-size: 13.012px;
    color: #b9135a;
  }
  .salesOrder-top{
    background-color: #f2f2f2;
    height: 40px;
    line-height: 42px;
    top: 17px;
    color: #444444;
    font-size: 15px;
    position: relative;
    padding-left: 7px;
  }
  .salesOrder-status{
    display: inline-block;
    float: right;
    margin-right: 9px;
  }
  .salesOrder-menu{
    font-size: 15px;
    padding: 35px 0px 10px 2px;
    font-weight: 500;
  }
  .user-img{
    float: left;
  }
  .user-img img{
    display: block;
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }
  .single_detail_list_people{
    font-size: 14px;
    color: #666;
    padding-left: 55px;
  }
  .single_detail_list_people div{
    float: right;
    margin-right: 12px;
  }
  .single_detail_list_explain{
    padding-left: 55px;
    padding-bottom: 12px;
    margin-top: 5px;
    font-size: 15px;
    color: #333333;
  }
  .salesOrder-ul li{
    border-bottom: 0.027rem solid #f4f4f4;
    padding-top: 20px;
    padding-bottom: 10px;
  }

  .video-wapper {
    height: auto;
    padding-left: 55px;
  }

  .video-wapper img{
    max-height: 70px;
    max-width: 70px;
    margin-right: 4px;
  }

  .video-wapper video {
    max-width:7.73rem;
  }

</style>
